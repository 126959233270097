$bg-color-black: #1f1f1f;
$bg-color-dark: #3d3d3d;
$bg-color-dark-gray: #dcdcdc;
$bg-color-light-gray: #f7f7f9;
$bg-color-white: #ffffff;
$bg-color-blue: #488cff;
$color-black: #616161;
$color-gray: #b1b1b1;
$color-white: #f3f3f3 !important;
$color-light-gray: #838383;
$border-radius: 5px !important;
$font-size-small: 1.4rem !important;

$list-x-padding: 0 15px;

.video-document-container {
  height: 100%;
  max-width: 100%;
  background-color: $bg-color-white;
  min-height: -webkit-fill-available;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;

  button {
    touch-action: manipulation;
  }

  .folder-dropdown-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  .list-container {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
  }

  .header {
    height: 64px;
    max-width: 100%;
    border-bottom: 1px solid var(--gray3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    &.vertical {
      height: auto;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2;
      background-color: white;
      padding: 12px;
      border-bottom: 0;
    }

    .header-left-container {
      display: flex;
      align-items: center;
      max-width: calc(100% - 154px);

      .list-open-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 4px;
        width: 28px;
        height: 28px;
        border-radius: 8px;

        &:hover {
          background-color: var(--gray3);
        }

        &:active {
          background-color: var(--gray2);
        }
      }

      .note-folder-selector-container {
        position: relative;
        max-width: calc(100% - 28px);
        flex: 1;

        .note-folder-selector-button {
          /* s of reset button */
          background: inherit;
          border: none;
          box-shadow: none;
          -webkit-tap-highlight-color: transparent;
          box-sizing: border-box;
          outline-style: none;

          &:focus {
            outline: 0;
            border: none;
          }
          padding: 0;
          /* e of reset button  */
          width: 100%;

          display: flex;
          align-items: center;
          cursor: pointer;

          p {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .note-folder-selector-button-icon-container {
            margin-left: 4px;
            border-radius: 8px;

            &:hover {
              background-color: var(--gray3);
            }

            &:active {
              background-color: var(--gray2);
            }
          }
        }

        .note-folder-selector-dropdown {
          list-style: none;
          margin: 0;
          padding: 8px 0px;
          width: 280px;
          max-height: 400px;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 48px;
          background: #ffffff;
          box-shadow: var(--boxShadow2);
          border-radius: 8px;
          z-index: 3;

          overflow-x: hidden;
          overflow-y: visible;

          .note-folder-list-item {
            width: 100%;
            padding: 4px 16px;
            cursor: pointer;

            &:hover {
              background-color: var(--gray3);
            }
          }
        }
      }
    }

    .header-right-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: fit-content;

      .my-note-button {
        display: flex;
        margin-right: 15px;
      }

      .video-note-dropdown-button-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 8px;

        &:hover {
          background-color: var(--gray2);
        }

        &:active {
          background-color: var(--gray3);
        }
      }

      .history-btn-container,
      .share-btn-container {
        color: $color-gray;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .history-btn {
          width: 18px;
          margin-right: 5px;
        }

        .share-btn {
          width: 24px;
        }
      }

      .my-docs-link {
        color: $color-black;
      }

      & > div > button {
        padding: 0;
        .help-icon {
          height: 24px;
          cursor: pointer;
        }
      }

      a {
        display: flex;
        align-items: center;
      }

      .help-icon-img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      .help-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .editor-container {
    flex: 1 1 auto;
    padding-left: 20px;

    &.horizontal {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .editor-vertical-scroll-wrapper {
      max-width: 850px;
      margin: 0 auto;
    }
    padding-top: 42px;

    .folder-create-icon {
      width: 30px;
      cursor: pointer;
    }
  }

  .save-button-wrapper {
    padding: 6px 9px 6px 8.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 36px;
    border-radius: 4px;
    background-color: var(--gray1);
    cursor: pointer;
    z-index: 1;

    .save-button-icon-wrapper {
      margin-right: 1px;
    }
  }

  .desktop-capture-bar-container {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    gap: 10px;
    bottom: 20px;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    z-index: 1;
  }

  .desktop-capture-tooltip {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
  }

  .capture-button-container {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 4px 0;
    z-index: 1;

    .capture-button-positioner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .capture-button {
      // S of reset button
      background: inherit;
      border: none;
      box-shadow: none;
      -webkit-tap-highlight-color: transparent;
      box-sizing: border-box;
      outline-style: none;
      padding: 0;
      position: relative;

      &:focus {
        outline: 0;
        border: none;
      }
      // E of reset button

      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      width: 84px;
      height: 52px;
      border-radius: 16px;
      cursor: pointer;

      &:hover {
        background-color: var(--gray2);
      }

      &:active {
        background-color: var(--gray3);
      }

      .capture-button-overlay-area {
        position: absolute;
        left: 0px;
        top: 0;
        width: 80px;
        height: 52px;
      }

      .button-text {
        font-size: 1rem;
        line-height: 1.6rem;
        text-align: center;
        color: var(--gray17);
      }

      .button-text-disabled {
        color: var(--gray5);
      }

      .setting-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 2px;
        top: 6px;
        width: 22px;
        height: 24px;
        border-radius: 8px;

        &:hover {
          background-color: var(--gray5);
        }
      }
    }
  }

  .help-button-container {
    position: absolute;
    bottom: 20px;
    right: 32px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid var(--gray4);
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: var(--gray2);
    }
  }

  .beta-help-button-container {
    position: absolute;
    bottom: 20px;
    right: 26px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .vdocs-onboarding-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1061;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: hidden;

    .vdocs-onboarding-img {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      cursor: pointer;

      &.step1 {
        bottom: 0;
      }

      &.step2 {
        top: -140px;
      }

      &.step3 {
        top: 0;
      }
    }
  }
}
